/* The following block can be included in a custom.scss */

@import url("https://fonts.googleapis.com/css?family=Barlow:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");

/* make the customizations */

$text-black: #163c5d;

// $primary: #163c5d; 
// $dark: #414f5f;
// $sa: #23ceae;
// $theme-colors: (
//     "info": gold,
//     "danger": tomato,
// 	  "primary": #163d5f,
// 	  "denim": #163d5f,
//     "sa": #23ceae,
// );

// $bg-colors: (
// 	"primary": #163c5d,
//   "sa": #23ceae
// );

:root {
	--bs-body-font-family: "Barlow";
  --bs-btn-font-family: "Barlow";
  --bs-body-color: #18242f;
  }

.container  {
	font-family: Barlow;
  color: #18242f;
	--bs-gutter-x: 0;
}

.container-fluid {
	font-family: Barlow;
  color: #18242f;
	--bs-gutter-x: 0;
}

// $spacers: (
//   0: 0,
//   1: $spacer * .25,
//   2: $spacer * .5,
//   3: $spacer,
//   4: $spacer * 1.5,
//   5: $spacer * 3,
//   6: $spacer * 10,
// );

$custom-control-indicator-checked-bg: #163c5d;

.btn-group {
	@media screen and (max-width: 991px) {
	  transform: scale(0.9);
	}
	@media screen and (max-width: 767px) {
	  transform: scale(0.825);
	}
	@media screen and (max-width: 575px) {
	  transform: scale(0.55);
	}
  }

  // .btn-group .btn {
  //   @media screen and (max-width: 991px) {
  //     transform: scale(0.9);
  //   }
  //   @media screen and (max-width: 767px) {
  //     transform: scale(0.825);
  //   }
  //   @media screen and (max-width: 575px) {
  //     transform: scale(0.75);
  //   }
  //   }

  
  $container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1400px
  );
  
  $grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1500px
  );

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
