.toggle-switch {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  width: 130px; // Custom width for the button.

  &-checkbox {
    display: none;
  }

  &-label {
    display: flex;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 10px; // Reduced rounded borders.
    margin: 0;
    width: 100%;
    height: 34px;
  }

  &-inner {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #bbb;
    border-radius: 10px 0px 0px 10px; // Reduced rounded borders.
    transition: background-color 0.3s ease-in;
  }

  &-inner.yes {
    border-radius: 20px 0px 0px 20px;
    &:before {
      content: "Yes";
    }
  }

  &-inner.no {
    border-radius: 0px 20px 20px 0px;
    &:after {
      content: "No";
    }
  }

  &-inner:first-child {
    border-right: none; // Remove the right border from the first inner element.
  }

  &-checkbox:checked + &-label {
    .toggle-switch-inner.yes {
      background-color: #163c5d;
      color: #fff;
    }

    .toggle-switch-inner.no {
      background-color: #fff;
      color: #000;
    }
  }

  &-checkbox:not(:checked) + &-label {
    .toggle-switch-inner.yes {
      background-color: #fff;
      color: #000;
      transition: background-color 0.3s ease-in;
    }

    .toggle-switch-inner.no {
      background-color: #163c5d;
      color: #fff;
    }
  }

  .toggle-switch-inner.unselected {
    background-color: #fff !important;
    color: #000 !important;
  }
}

//   .toggle-switch {
// 	@media screen and (max-width: 991px) {
// 	  transform: scale(0.9);
// 	}
// 	@media screen and (max-width: 767px) {
// 	  transform: scale(0.825);
// 	}
// 	@media screen and (max-width: 575px) {
// 	  transform: scale(0.75);
// 	}
//   }
