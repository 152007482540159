
body {
  margin: 0;
  font-family: 'Barlow' -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  /* overflow: scroll; */
  overflow-x: hidden;
  overflow-y: initial;
}


.nav-link:active {
  background: transparent !important;
}

/* .nav-pills .nav-link:active {
  background: transparent !important;
  padding: 0; 
  margin: 0;
  width: 100%;
} */

.sign-in-container { 
  
  padding: 0 !important; 
  margin: 0 !important; 
  box-sizing: border-box !important;
  overflow: hidden !important;
}

a:active {
  background-color: white
}