.carousel-inner {
	height: 100%; 
}

.form-control {
	font-size: calc(.5em + .75vw) !important; 
	border-radius: 0 !important;
	border-top: 0 !important; 
	border-right: 0 !important; 
	border-left: 0 !important;
	border-bottom: 1px solid black !important; 
	background-color: transparent !important;
	
}

.form-control:focus {
	border-top: 0 !important; 
	border-right: 0 !important; 
	border-left: 0 !important;
	border-color: #163c5d !important;
	box-shadow: 0 0 0 0.25rem hsl(208, 62%, 23%, 25%) !important;
  }

.login { 
	font-size: 12pt !important;
	border-radius:  15px !important; 
	border: 2px solid #163c5d !important; 
	background-color: rgb(255, 250, 250) !important;
	color: #163c5d !important;
	width:  75% !important;
	margin: auto;
}

.login:focus { 
	/* border-color: rgb(43, 187, 127) !important; */
	/* box-shadow: 0 0 0 0.15rem rgb(43, 187, 127) !important; */
	border: 3px solid rgb(43, 187, 127) !important; 
}

.form-select { 
	/* width: 75% !important; */
	font-size:  calc(.5em + .75vw) !important; 
	border-radius: 0 !important;  
	border-top: 0 !important;  
	border-bottom: 0 !important;  
	border-left: 0 !important; 
	border-right: 0 !important; 
	border-bottom: 1px solid black !important;
	background-color: transparent !important;
}

.hover-denim:focus, 
.hover-denim:active, 
.hover-denim:hover { 
	background: #163c5d !important;
	background-color: #163c5d !important;
	color: white !important
}

.form-select:focus { 
	border-top: 0 !important; 
	border-right: 0 !important; 
	border-left: 0 !important;
	border-color: #163c5d !important;
    box-shadow: 0 0 0 0.25rem hsl(208, 62%, 23%, 25%) !important;
}

.form-select option {
    background-color: #f1f3f2 !important;
}


select:active, select:hover {
	outline: red !important;
  }

.clickable {
	cursor: pointer;
}

.custom-range::-webkit-slider-thumb {
	background: #163c5d !important;
  }
  
.custom-range::-moz-range-thumb {
	background: #163c5d !important;
  }
  
.custom-range::-ms-thumb {
	background: #163c5d !important;
  }

.center-icon-card { 

		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
  }

  /* span.circle {
	background: white;
	border-radius: 50%;
	border: 3px solid #23ceae;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	color: #6e6e6e;
	display: inline-block;
	font-weight: bold;
	height: 34px;
	margin-right: 5px;
	text-align: center;
	width: 34px;
  } */


  /*Circle Span Update  */

  .circle-container {
	display: flex !important;
	align-items: center !important;
  }
  
  span.circle {
	background: #fff ;
	border: 3px solid #9099a2 ; /* Inactive color */
	border-radius: 50%;
	color: #9099a2; /* Inactive color */
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	height: 34px;
	margin-right: 5px;
	text-align: center;
	width: 34px;
	position: relative; /* Added for line positioning */
	margin-right: 25px;
  }
  
  span.circle:before {
	content: '\00a0';
  }

  span.circle.icon:before{
	content: none;
  }
  
  span.circle-filled {
	position: relative;
  }
  
  span.circle-filled::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	background: #9099a2; /* Inactive color */
	border-radius: 50%;
	width: 24px;
	height: 24px;
	transform: translate(-50%, -50%);
  }
  
  span.circle-half {
	position: relative;
  }
  
  span.circle-half::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	background: #9099a2; /* Inactive color */
	border-radius: 50%;
	width: 24px;
	height: 24px;
	clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
	transform: translate(-50%, -50%);
  }
  
  .circle-container span.circle::after {
	content: "" !important;
	position: absolute !important;
	top: 50% !important;
	left: 100% !important;
	width: 45px !important; /* Adjust the length of the connecting line */
	height: 3px !important;
	background: #9099a2 !important; /* Inactive color */
	transform: translateY(-50%) !important;
	/* z-index: -1 !important; */
  }
  
  span.circle:last-child::after {
	display: none;
  }
  
  span.circle.active {
	border-color: #23ceae;
	color: #23ceae;
  }
  
  span.circle.active.circle-filled::before {
	background: #23ceae;
  }
  
  span.circle.active.circle-half::before {
	background: #23ceae;
  }
  
  span.circle.active::after {
	background: #23ceae;
  }


  span.circle-hatch {
	position: relative;
  }
  
  span.circle-hatch::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	background: repeating-linear-gradient(
	  -45deg,
	  #9099a2,
	  #9099a2 2px,
	  transparent 2px,
	  transparent 4px
	);
	border-radius: 50%;
	width: 24px;
	height: 24px;
	transform: translate(-50%, -50%);
  }
  
  span.circle.active.circle-hatch::before {
	background: repeating-linear-gradient(
	  -45deg,
	  #23ceae,
	  #23ceae 2px,
	  transparent 2px,
	  transparent 4px
	);
  }
  /* End of Circle span update */
  .hr1 {
    height: 1px;
    background: blue;
}

.hr2 {
    height: 0;
    border-bottom: 1px solid green;
}

  .denim-border {
	border: 2px solid #163c5d !important;
  }

  .responsive-font {
	font-size:  calc(.50em + .50vw) !important; 
  }

  .light-denim { 
	background-color: #D0D8DE !important;
  }

  .selectable-card { 
	background-color: #d4dfdc !important; 
	/* border: 2px solid gray !important; */
	cursor: pointer;
	border-radius: 50% !important;
	height: 35px !important;
	width: 35px !important;
  }



  .selectable-card:hover { 
	/* background-color: #9099a2 !important;  */
	opacity: 1;
	/* border: 2px solid #163c5d !important; */
	color: white;
  }

  .selected-card {
	/* background-color: #9099a2 !important;  */
	/* border: 2px solid #163c5d !important; */
	cursor: pointer;
	color: white;
	border-radius: 50% !important;
	height: 35px !important;
	width: 35px !important;
  }

  .selected-card select-alt {
	background-color: #ffb236 !important;
  }

  .selectable-card select-alt:hover {
	background-color: #ffb236 !important;
  }

  .required::after {
	content: " *"; 
	color: darkred 
  }

  html {
	overflow-x: initial !important;
  }

  .question { 
	background: linear-gradient(to bottom, rgba(255,255,255,0.7), rgba(237,237,237,0.7));
  } 

  .arrow {
  	font-size: 45px;
  	color: #163c5d;
  }

  /* ending */

  .checkmark__circle {
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: 2;
	stroke-miterlimit: 10;
	stroke: #2b7c77;
	fill: none;
	animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .checkmark {
	width: 56px;
	height: 56px;
	border-radius: 50%;
	display: block;
	stroke-width: 2;
	stroke: #fff;
	stroke-miterlimit: 10;
	margin: 10% auto;
	box-shadow: inset 0px 0px 0px #2b7c77;
	animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  
  .checkmark__check {
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @keyframes stroke {
	100% {
	  stroke-dashoffset: 0;
	}
  }
  @keyframes scale {
	0%, 100% {
	  transform: none;
	}
	50% {
	  transform: scale3d(1.1, 1.1, 1);
	}
  }
  @keyframes fill {
	100% {
	  box-shadow: inset 0px 0px 0px 30px #2b7c77;
	}
  }

  .bold { 
	font-weight: bold !important;
  }

  .custom-toggle-container {
	width: 180px;
	height: 40px;
	background-color: #d6dce3;
	border-radius: 20px;
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	transition: background-color 0.3s ease;
  }
   
 .custom-toggle-container-yes {
	background-color: #30475f;
  }
  
  .custom-toggle-container-no {
	background-color: #248c70;
  }
  
  .switch {
	width: 50px;
	height: 30px;
	background-color: #fff;
	border-radius: 15px;
	position: absolute;
	left: 5px;
	transition: left 0.3s ease;
  }
  
  .switch-neutral {
	left: 65px;
  }
  
  .switch-yes {
	left: 5px;
  }
  
  .switch-no {
	left: 125px;
  }
  
  .label-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 0 15px;
	font-weight: bold;
	color: #333;
  }
  
  .label {
	user-select: none;
	color: #fff;
  }

  .accordion-button[aria-expanded="true"] {
	background-color: #23ceafb4 !important;
	color: white !important;
  }

  .form-check-input:checked {
    background-color: #23ceae !important;
    border-color: #163c5d;
}

.accordion-button:not(.collapsed)::after{
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.survey-button { 
    border: 0 !important;
    background-size: 100% 200%; 
    background-image: linear-gradient(to bottom, #fff 50%, #163c5d 50%);
    transition: 
      background-position .2s ease-in-out, 
      color .2s ease-in-out !important;
  }


.survey-button:hover, 
.survey-button:focus {
  background-position: 0 100% !important;
  color: #fff !important;
}