* {
  font-family: Barlow;
}
.border-right {
  border-right: 2px solid lightgray !important;
  margin-right: 10px;
}

li.line {
  width: 2em;
  height: 2em;
  text-align: center;
  line-height: 2em;
  border-radius: 1em;
  background: #d4dfdc;
  margin: 0 1em;
  display: inline-block;
  color: white;
  position: relative;
}

li.line::before {
  content: "";
  position: absolute;
  top: 0.9em;
  left: -4em;
  width: 4em;
  height: 0.2em;
  background: #163c5d;
  z-index: -1;
}

li.line:first-child::before {
  display: none;
}


.active-tab {
  background: #23ceae !important;
}

.nav-link.active {
  color: white !important;
  background: #23ceae !important;
}

.nav-link {
  color: #23ceae !important;
}

/* .active ~ li.line {
	background: #a8c2cf;
  }
  
  .active ~ li.line::before {
	background: #a8c2cf;
  } */

li {
  position: relative; /* necessary for positioning the :after */
}

li.done {
  list-style: none; /* remove normal bullet for done items */
}

li.done:after {
  content: "\2713";
  /* display: inline-block; */
  color: #4d7c2a;
  padding: 0 6px 0 0;
  font-weight: 800;

  /* position the checkbox */
  position: absolute;
  left: -16px;
  top: 0px;
}

li.incomplete {
  list-style: none; /* remove normal bullet for done items */
}

li.incomplete:after {
  content: "\2A09";
  /* display: inline-block; */
  color: #7c2d2a;
  padding: 0 6px 0 0;
  font-weight: 800;

  /* position the checkbox */
  position: absolute;
  left: -16px;
  top: 0px;
}

.responsive {
  @media screen and (max-width: 991px) {
    transform: scale(0.9) !important;
  }
  @media screen and (max-width: 767px) {
    transform: scale(0.825) !important;
  }
  @media screen and (max-width: 575px) {
    transform: scale(0.75) !important;
  }
}

@keyframes bouncing {
  from,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.bouncing {
  position: absolute;
  font-size: 2em;
}

.bouncing:hover {
  animation-duration: 2s;
  animation-name: bouncing;
  animation-iteration-count: infinite;
}

.progress-bar {
  background-color: #2b7c77 !important;
}

.wrapper:hover .fa-gear {
  /* color: red; */
  transition: 0.9s;
  transform: rotateY(180deg);
}

.modal-content {
  border-radius: 0px !important;
  border: 0 !important;
  font-family: Barlow;
}

.sa {
  background-color: #23ceaf41 !important;
}

.btn-close {
  transition: transform 0.4s ease-in !important;
}

.btn-close:hover {
  opacity: 1;
  transform: rotate(180deg) !important;
  border-radius: 50px;
  border-color: #23ceae;
}

.nav-tabs .nav-item .nav-link {
  border-radius: 0px !important;
  border-bottom: 3px solid #23ceae !important;
  margin-right: 10px;
}

.nav-tabs .nav-item .nav-link:hover {
  background: #9099a248 !important;
  border-bottom: 3px solid #23ceae !important;
  color: white !important;
}

.nav-tabs {
  border: 0px !important;
}

.nav-tabs.li:hover {
 
  border: 1px solid #23ceae !important;
}

.darkgray {
  background-color: #9099a2 !important;
}

.gold {
  background-color: #ffb236 !important;
}

.teal {
  background-color: #23ceafbe !important;
}

.text-thin {
  font-weight: 300 !important;
  word-spacing: 0.25rem;
}

.sa-card {
  position: relative;
  background-color: white;
  border: none;
}

.sa-card::before,
.sa-card::after {
  content: "";
  position: absolute;
  width: 2px;
  height: calc(100% - 20px);
  background-color: #23ceae;
}

.sa-card::before {
  top: 20px;
  left: -1px;
}

.sa-card::after {
  bottom: 20px;
  right: -1px;
}

.sa-card .short-border {
  content: "";
  position: absolute;
  background-color: #23ceae;
  width: calc(100% - 50px);
  height: 2px;
}

.sa-card .top-border {
  top: -1px;
  left: 50px;
}

.sa-card .bottom-border {
  bottom: -1px;
  right: 50px;
}

.sa-card .circle-cap {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #23ceae;
  border-radius: 50%;
}

.sa-card .top-left-circle {
  top: 10px;
  left: -5px;
}

.sa-card .bottom-right-circle {
  bottom: 10px;
  right: -5px;
}

.gold-border {
  border: 2px solid #ffb236 !important
}