@media (min-width: 576px) {
  .h-sm-100 {
    height: 100%;
  }
}

.bg-lead {
  background: transparent linear-gradient(to right, #163c5d 0%, #52869f 100%) 0%
    0% no-repeat padding-box;
}

.bg-lead-rev {
  background: transparent
    linear-gradient(to left, #163c5d 0%, hsl(208deg 62% 23%) 100%) 0% 0%
    no-repeat padding-box;
}

.bg-lead-rev-white {
  background: transparent linear-gradient(to left, #f2f4f5 0%, #d6dce3) 0% 0%
    no-repeat padding-box;
}

.bg-denim {
  background-color: rgba(22, 60, 93, 0.8);
}

.img-invert {
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
}

.intro-content {
  clip-path: polygon(0 0, 100% 0, 100% 35vh, 0 100%);
  background: transparent linear-gradient(to left, #f2f4f5 0%, #d6dce3) 0% 0%
    no-repeat padding-box;
}

.disclaimer {
  background: transparent linear-gradient(to right, #f2f4f5 0%, #d6dce3) 0% 0%
    no-repeat padding-box;
}

.no-bullet {
  list-style-type: none !important;
}

html {
  overflow-x: initial !important;
}

@media screen and (min-width: 768px) {
  .full-nav-item {
    width: 100%;
    align-items: center;
    display: flex;
    background-color: #f8f9fa;
    border-radius: 25px 0px 0px 25px;
    color: rgba(22, 60, 93, 0.8);
    padding: 0px;
  }

  .full-nav-link {
    color: rgba(22, 60, 93, 0.8);
  }
}

.disabled-item {
  color: gray !important;
  /* font-style: italic !important; */
}

.disabled-icon {
  color: gray !important;
}

.selected-icon {
  color: #ffb236 !important;
}

@media screen and (min-width: 768px) {
  .selected-icon {
    color: #163c5d !important;
  }
}

.unselected-icon {
  color: #fff !important;
}

ul.checkmark {
  list-style-type: none;
}
ul.checkmark li:before {
  content: "\2713\0020";
  font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial;
}

.card-1,
.card-2 {
  border-left: 10px solid #23ceae;
  background-color: white;
  color: #23ceae;
  margin-bottom: 20px;
  border: 3px solid #23ceae !important;
}

.card-1:before,
.card-2:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translateY(-50%);
  height: 50px;
  width: 50px;
  background-color: white;
  /* border-radius: 50%; */
  /* border: 10px solid #23ceae; */
  font-size: 36px;
  text-align: center;
  line-height: 50px;
}

.card-1:before {
  content: "1";
  font-weight: bold;

  /* Set the border size and color */
  -webkit-text-stroke: 2px #23ceae;
  text-stroke: 2px #23ceae;

  /* Set the fill color */
  -webkit-text-fill-color: white;
  text-fill-color: white;
}

.card-2:before {
  content: "2";

  font-weight: bold;

  /* Set the border size and color */
  -webkit-text-stroke: 2px #23ceae;
  text-stroke: 2px #23ceae;

  /* Set the fill color */
  -webkit-text-fill-color: white;
  text-fill-color: white;
}

.transition-button {
  border: 0 !important;
  background-size: 100% 200%;
  background-image: linear-gradient(to bottom, #23ceae 50%, #163c5d 50%);
  transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out !important;
}

.transition-button:hover,
.transition-button:focus {
  background-position: 0 100% !important;
  color: #fff !important;
}

@media (max-width: 767.98px) {
  .transition-button {
    transition: none !important;
    background-image: linear-gradient(to bottom, #23ceae, #23ceae) !important;
  }
}

.teal-light {
  background-color: #23ceaf13 !important;
  color: #163c5d !important;
}

.margin-right {
  margin-right: 10px;
}

.sidebar-nav {
  border: 0px !important;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #23ceaf13 !important;
  color: #163c5d !important;
}
