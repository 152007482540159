.App {
  text-align: center;
  font-family: Barlow;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media (max-width: 767.98px) {
  .transition-button {
    transition: none !important;
    background-image: linear-gradient(to bottom, #23ceae, #23ceae) !important;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.login-button { 
  border-radius: 0;
  border: 0 !important;
  background-size: 100% 200%; 
  background-image: linear-gradient(to bottom, #163c5d 50%, #23ceae 50%);
  transition: 
    background-position .2s ease-in-out, 
    color .2s ease-in-out !important;
}

.login-button:hover, 
.login-button:focus {
  background-position: 0 100% !important;
  color: #fff !important;
}

.transition-button { 
  border: 0 !important;
  background-size: 100% 200%; 
  background-image: linear-gradient(to bottom, #23ceae 50%, #163c5d 50%);
  transition: 
    background-position .2s ease-in-out, 
    color .2s ease-in-out !important;
}

.login-button:hover, 
.login-button:focus {
  background-position: 0 100% !important;
  color: #fff !important;
}


.shimmer {
  color: lightgrey;
  display:inline-block;
  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  font-size: 50px;
  max-width:200px;
}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
}

